<template>
  <div class="mobileIndex">
    <div class="mobileIndexHead">
      <img class="logo1" src="@/assets/mobile/img/logo.png" alt="" />
    </div>
    <p class="p1">实时动态</p>
    <p class="p2">保证实时音视频直播宝宝在园成长</p>
    <p class="p2">不同场景一键触达，随时掌握宝宝状态</p>
    <div class="dw">
      <span><img class="img1" src="@/assets/mobile/img/1.png" alt="" /></span>
      <div class="mengban"></div>
      <div class="downBtn">
        <div @click="toIos" class="iosDown">
          <img src="@/assets/img/ios.png" alt="" />
          ios下载
        </div>
        <div @click="toAndriod" class="iosDown" style="margin-left: 24px">
          <img src="@/assets/img/android.png" alt="" />
          安卓下载
        </div>
      </div>
      <p class="bottomS btm1">Copyright © 2018 非络通达科技有限公司</p>
      <p class="bottomS" style="padding-bottom: 20px">
        All Rights Reserved.川公网安备51010702043066号 蜀ICP备17037550号-6
      </p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      androidMsg: {},
      isBrowser: false,
    };
  },
  mounted() {
    this.getVersion();
    this.isWeixin();
  },
  methods: {
    async getVersion() {
      axios
        .get("https://api.v2.jinshuy.com/mamserver/api/version/latestVersion")
        .then((res) => {
          this.androidMsg = res.data.data[0];
        });
    },
    isWeixin() {
      this.isBrowser = false;
      var u = navigator.userAgent;
      if (u.indexOf("MicroMessenger") > -1) {
        this.isBrowser = false;
      } else {
        this.isBrowser = true;
      }
    },
    toIos() {
      if (this.isBrowser) {
        window.open(
          "https://apps.apple.com/cn/app/%E9%87%91%E6%A0%91%E4%B8%AB/id1560759387#?platform=iphone"
        );
      } else {
        this.$router.push("/mdownload");
      }
    },
    toAndriod() {
      if (this.isBrowser) {
        window.open(this.androidMsg.apkPath);
      } else {
        this.$router.push("/mdownload");
      }
    },
  },
};
</script>
<style lang="less">
.mobileIndex {
  text-align: center;
  .mobileIndexHead {
    width: 100%;
    height: 44px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.16);
    .logo1 {
      float: left;
      width: 80px;
      height: 26px;
      margin: 9px 15px;
    }
  }
  .p1 {
    text-align: center;
    color: #1e1e1e;
    font-size: 19px;
  }
  .p2 {
    padding-bottom: 5px;
    text-align: center;
    font-size: 12px;
    color: #30290e;
    margin: 0;
  }
  .img1 {
    width: 263px;
    height: 514px;
  }
  .dw {
    width: 100%;
    position: relative;
  }
  .mengban {
    position: absolute;
    top: 374px;
    width: 100%;
    height: 100px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }
  .downBtn {
    width: 100%;
    position: absolute;
    top: 474px;
    display: flex;
    justify-content: center;
    clear: both;
    .iosDown {
      float: left;
      width: 130px;
      // height: 39px;
      line-height: 39px;
      background: #fbd646;
      border-radius: 6px;
      font-size: 16px;
      img {
        width: 15px;
        // height: 15px;
        float: left;
        margin: 10px 0 0 15px;
      }
    }
  }
  .btm1 {
    padding-top: 30px;
  }
  .bottomS {
    margin: 0;
    color: #30290e;
    text-align: center;
    font-size: 9px;
  }
}
</style>
